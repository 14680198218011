import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import './styles.css'
import { getCDNOptimizedImage } from '../../utils'

const PageNavbar = () => {
    const isActive = path => window.location.pathname === path

    return (
        <div className="navbar-container">
            <div className="navbar-icon">
                <Link to="/">
                    <img
                        alt="RallyNE logo"
                        src={getCDNOptimizedImage({ imagePath: "/RallyNE_MainLogo.svg", height: '40px' })}
                    />
                </Link>
            </div>
            <div className="navbar-items">
                <Link className={cx('navbar-item', { 'active': isActive('/search')})} to="/search">Routes</Link>
                <Link className={cx('navbar-item', { 'active': isActive('/route/new')})} to="/route/new">Submit a route</Link>
                <span className="navbar-item-split"></span>
                <Link className={cx('navbar-item', { 'active': isActive('/faq')})} to="/faq">FAQ</Link>
                <Link className={cx('navbar-item', { 'active': isActive('/about-us')})} to="/about-us">About us</Link>
            </div>
        </div>
    )
}

export default PageNavbar