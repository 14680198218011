import React, { useState } from "react"
import { Button, Card, Container } from 'react-bulma-components'

import { createRoute } from '../../api'

import './styles.css'

const RouteSubmissionView = () => {
    const [routeObject, setRouteObject] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleInput = (key, value) => {
        const newRouteObj = {...routeObject}
        newRouteObj[key] = value
        setRouteObject(newRouteObj)
    }

    const handleSubmit = () => {
        setIsSubmitting(true)
        createRoute(routeObject)
            .then((routeId) => window.location = `/route/${routeId}`)
    }

    return (
        <Container className="detail-view-container">
            <h1 className="route-title">Submit a new route</h1>
            <p className="route-description">Fill in these prompts and submit your favorite driving routes so others can view and drive them in the app!</p>
            <Card className="detail-card detail-stats-card">
                <h2>Route details</h2>
                <div className="route-input-container">
                    <label htmlFor="route-title">Route title</label>
                    <input name="route-title" id="route-title" type="text" placeholder="Mountain cruise..." value={routeObject.title} onChange={e => handleInput('title', e.target.value)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="short-description">Short description</label>
                    <input name="short-description" id="short-description" type="text" placeholder="A short drive through the mountain pass" value={routeObject.descriptionShort} onChange={e => handleInput('descriptionShort', e.target.value)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="long-description">Long description</label>
                    <textarea name="long-description" id="long-description" type="text" placeholder="Speed limits, nearby amenities, favorite moments, photo ops, ..." value={routeObject.descriptionLong} onChange={e => handleInput('descriptionLong', e.target.value)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="route-type">Route type</label>
                    <select name="route-type" id="route-type" options={[{ label: 'Mountain', value: 'mountain' }]} value={routeObject.routeType} onChange={e => handleInput('routeType', e)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="duration">Est. duration, minutes</label>
                    <input name="duration" id="duration" type="number" placeholder={30} min={0} max={300} value={routeObject.timeMins} onChange={e => handleInput('timeMins', e.target.value)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="speed-level">Speed level</label>
                    <select name="speed-level" id="speed-level" options={[{ label: 'Fast', value: 'fast' }]} value={routeObject.speed} onChange={e => handleInput('speed', e)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="starting-pos">Starting position (lat,long)</label>
                    <input name="starting-pos" id="starting-pos" type="text" placeholder="42.5532091,-70.8468352" value={routeObject.pinLatLong} onChange={e => handleInput('pinLatLong', e.target.value)} />
                </div>
                <div className="route-input-container">
                    <label htmlFor="route-link">Route link</label>
                    <input name="route-link" id="route-link" type="text" placeholder="https://www.google.com/maps/dir/..." value={routeObject.routeLink} onChange={e => handleInput('routeLink', e.target.value)} />
                </div>
                <div>
                    <Button color="primary" onClick={handleSubmit} loading={isSubmitting}>Submit</Button>
                </div>
            </Card>
        </Container>
    )
}

export default RouteSubmissionView
