import mockData from './mockData'

const axios = require('axios').default

const api = axios.create({
  baseURL: '/api'
})

export const fetchRoutes = (mapBoundaries, filterSet) => {
  const rawRows = mockData
  const { north, south, east, west } = mapBoundaries || {}
  const { routeType, speed, duration } = filterSet || {}
  let formattedData = []
  console.log(mapBoundaries, filterSet)
  console.log(mockData)

  for (let i = 0; i < rawRows.length; i++) {    
    const newRouteData = rawRows[i]
    const lat = Number.parseFloat(newRouteData.pinLatLong.split(',')[0])
    const lng = Number.parseFloat(newRouteData.pinLatLong.split(',')[1])

    // Ask each filter type whether this route should be added
    let shouldIncludeRoute = []

    // Map boundaries
    if (north && south && east && west && newRouteData.pinLatLong) {
      const isWithinMapBoundaries = ((lat <= north && lat >= south) && (lng >= west && lng <= east))
      shouldIncludeRoute.push(isWithinMapBoundaries)
    }
    
    // Apply routeType filtering
    if (routeType && newRouteData.routeType) {
      shouldIncludeRoute.push(newRouteData.routeType.toLowerCase() === routeType.toLowerCase())
    }

    // Apply duration filtering
    if (duration && newRouteData.timeMins) {
      shouldIncludeRoute.push(valueWithinBuffer(duration, newRouteData.timeMins, 15))
    }

    // Apply duration filtering
    if (speed && newRouteData.speed) {
      shouldIncludeRoute.push(newRouteData.speed.toLowerCase() === speed.toLowerCase())
    }
    
    // If all filter checks voted yes or if there were no filters applied, add the route
    if (shouldIncludeRoute.filter(item => !Boolean(item)).length === 0) {
      formattedData = [...formattedData, newRouteData]
    }
  }
  console.log(formattedData)
  return new Promise((resolve, reject) => { return resolve(formattedData) })
  // return api.get('/routes', { params: { ...mapBoundaries, ...filterSet } })
  //   .then(({ data }) => data.routeList)
  //   .catch(e => console.error(e))
}

export const fetchRouteDetails = (id) => {
  console.log(id, mockData[id - 1])
  return new Promise((resolve, reject) => { return resolve(mockData[id - 1]) })
  // return api.get(`/routes/${id}`)
  //   .then(({ data }) => data.routeData)
  //   .catch(e => console.error(e))
}

export const createRoute = (routeObject) => {
  return api.post(`/routes`, routeObject)
    .then(({ data }) => data.routeData)
    .catch(e => console.error(e))
}

