import React, { useEffect, useState } from "react"
import { Button, Card, Container, Columns } from 'react-bulma-components'
import { useParams } from 'react-router'

import { fetchRouteDetails } from "../../api"

import MapComponent from './MapComponent'

import './styles.css'

const DetailView = ({ match }) => {
    const [routeObject, setRouteObject] = useState()
    const [copyText, setCopyText] = useState('Copy link')
    const [isCopying, setIsCopying] = useState(false)
    const [loading, setLoading] = useState(true)
    const { id } = useParams()

    useEffect(() => {
        console.log('fetching route number: ', id)
        fetchRouteDetails(id)
            .then(routeDetails => {
                setRouteObject(routeDetails)
                setLoading(false)
            })
    }, [])

    const copyRouteLinkToClipboard = () => {
        setIsCopying(true)

        Promise.all([
            navigator.clipboard.writeText(routeObject.routeLink),
            new Promise(resolve => setTimeout(resolve, 1000))
        ])
            .then(() => {
                setIsCopying(false)
                setCopyText('Copied!')
                setTimeout(() => setCopyText('Copy link'), 1000)
            })
            .catch(e => console.log('error copying route link: ', e))
    }


    if (loading) {
        return (
            <Container className="detail-view-container">
                <h1 className="route-title">loading...</h1>
            </Container>
        )
    }

    return (
        <Container className="detail-view-container">
            <h1 className="route-title">{routeObject.title}</h1>
            <Columns>
                <Columns.Column className="detail-view-column">
                    <MapComponent routeObject={routeObject} />
                    <p className="detail-description">{routeObject.descriptionLong}</p>
                </Columns.Column>
                <Columns.Column size={3} className="detail-view-column">
                    <Card className="detail-card detail-stats-card">
                        <h3>Route details</h3>
                        <Columns>
                            <Columns.Column size={4} className="detail-view-column">
                                <span>Type</span>
                                <span>Speed</span>
                                <span>Time</span>
                            </Columns.Column>
                            <Columns.Column className="detail-view-column">
                                <span>{routeObject.routeType}</span>
                                <span>{routeObject.speed}</span>
                                <span>{routeObject.timeMins} mins</span>
                            </Columns.Column>
                        </Columns>
                    </Card>
                    <Card className="detail-card">
                        <h3>Ready to drive?</h3>
                        <Button color='primary' onClick={() => window.open(routeObject.routeLink, '_blank')}>
                            Go to route
                        </Button>
                        <Button color='' onClick={copyRouteLinkToClipboard} loading={isCopying}>{copyText}</Button>
                    </Card>
                </Columns.Column>
            </Columns>
        </Container>
    )
}

export default DetailView;
