import React, { Fragment, useContext, useRef, useState } from 'react'
import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { Button } from 'react-bulma-components'
import { RoutesContext } from '../../RoutesContext';

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '6px'
};

const center = { lat: 42.423229, lng: -71.291250 };

const clusterOptions = {
  zoomOnClick: true,
  maxZoom: 13,
  minimumClusterSize: 3,
  imagePath:
    'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}

const SearchMapComponent = () => {
  const { routesList, updateMapBoundaries } = useContext(RoutesContext)
  const [currentLocation, setCurrentLocation] = useState();
  const [selectedMarker, setSelectedMarker] = useState();
  const mapRef = useRef();
  const mapBoundariesRef = useRef();

  const renderMarkers = (clusterer) => {
      return (
          <Fragment>
              {routesList.map((routeObject, idx) => {
                  if (routeObject.pinLatLong) {
                      const coords = {
                          lat: Number.parseFloat(routeObject.pinLatLong.split(',')[0]),
                          lng: Number.parseFloat(routeObject.pinLatLong.split(',')[1])
                      }
  
                      return (
                        <Marker 
                          position={coords} 
                          key={`marker-${idx}`} 
                          clusterer={clusterer}
                          onClick={() => setSelectedMarker({ position: coords, routeObject: routeObject })}
                        />
                      )
                  } else {
                    return null
                  }
              })}
              {currentLocation && (
                <Marker position={currentLocation} icon="https://imgur.com/VxWHtVc.png" />
              )}
          </Fragment>
      )
  }

  const renderInfoWindow = () => {
    const { position, routeObject } = selectedMarker

    return (
      <InfoWindow position={position} onCloseClick={() => setSelectedMarker(null)}>
        <div className='info-window-container'>
          <h3>{routeObject.title}</h3>
          <p>{routeObject.descriptionShort}</p>
          <Button color='primary' size="small" onClick={() => window.open(`/route/${routeObject.id}`, "_blank")}>View route</Button>
        </div>
      </InfoWindow>
    )
  }

  const onLoad = (map) => {
    mapRef.current = map;

    // Center on current location is user consents
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setCurrentLocation(pos);
          map.setCenter(pos);
        },
        () => {
          console.log('error callback')
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log('error with geolocation support')
    }
  };

  const onBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      
      // For each pair of lat and long values, translate them agnostic of the key values
      const listBounds = Object.values(bounds)
      const nestedListBounds = [Object.values(listBounds[0]), Object.values(listBounds[1])]

      const readableBounds = {
        north: Math.max(nestedListBounds[0][0], nestedListBounds[0][1]),
        south: Math.min(nestedListBounds[0][0], nestedListBounds[0][1]),
        east: Math.max(nestedListBounds[1][0], nestedListBounds[1][1]),
        west: Math.min(nestedListBounds[1][0], nestedListBounds[1][1]),
      }

      if (!mapBoundariesRef.current) {
        updateMapBoundaries(readableBounds)
      }

      mapBoundariesRef.current = readableBounds
    }
  };

  const updateStuff = () => updateMapBoundaries(mapBoundariesRef.current)

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDt7v_olyWmKuaVDxfWXRvxICbSkWPffGQ"
    >
      <GoogleMap
        onLoad={onLoad}
        onBoundsChanged={onBoundsChanged}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onDragEnd={updateStuff}
        onZoomChanged={updateStuff}
        onResize={updateStuff}
      >
        <MarkerClusterer options={clusterOptions}>
          {renderMarkers}
        </MarkerClusterer>
        {selectedMarker && renderInfoWindow()}
      </GoogleMap>
    </LoadScript>
  )
}

export default SearchMapComponent
