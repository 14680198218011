import React from "react"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import PageNavbar from './components/Navbar'
import PageFooter from './components/Footer'

import ScrollToTop from './components/ScrollToTop'
import LandingPageView from './components/LandingPageView'
import SearchView from './components/SearchView'
import DetailView from './components/DetailView'
import RouteSubmissionView from './components/RouteSubmissionView'
import AboutUsView from './components/AboutUsView'
import FAQView from './components/FAQView'

import './index.css'

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <PageNavbar />
          <div className="page-content">
            <Switch>
              <Route path="/route/new">
                <RouteSubmissionView />
              </Route>
              <Route path="/route/:id">
                <DetailView />
              </Route>
              <Route path="/search">
                <SearchView />
              </Route>
              <Route path="/faq">
                <FAQView />
              </Route>
              <Route path="/about-us">
                <AboutUsView />
              </Route>
              <Route exact path="/">
                <LandingPageView />
              </Route>
            </Switch>
          </div>
        </ScrollToTop>
      </Router>
      <PageFooter />
    </div>
  )
}

export default App;
