import React, { Fragment, useContext, useState } from "react"
import { useHistory } from 'react-router-dom'
import { Card, Container, Columns, Form, Button } from 'react-bulma-components'

import { RoutesContext, RoutesContextProvider } from '../../RoutesContext'
import MapComponent from "./MapComponent"

import './styles.css'

const ListItem = ({ routeObject }) => {
    const history = useHistory()

    return (
        <Card className="route-item-container" key={`route-item-${routeObject.id}`} onClick={() => history.push(`/route/${routeObject.id}`)}>
            <Columns>
                <Columns.Column className="route-item-column" mobile={{ size: 8 }}>
                    <h3>{routeObject.title}</h3>
                    <p>{routeObject.descriptionShort}</p>
                    <div className="route-labels-container">
                        <span>{routeObject.routeType}</span>
                        <span>{routeObject.speed}</span>
                    </div>
                </Columns.Column>
                <Columns.Column className="route-item-column" size={3} mobile={{ size: 4 }}>
                    <h2 className="route-time">{routeObject.timeMins}</h2>
                    <span>minutes</span>
                </Columns.Column>
            </Columns>
        </Card>
    )
}

const ListComponent = ({ isFetchingNewRoutes, routesList }) => (
    <>
        <h2>All routes</h2>
        <div className="route-items-subheader-container">
            <span>{routesList.length} route{routesList.length > 1 && 's'}</span>
            <span>Sorted by duration</span>
        </div>
        <div className="route-cards-container">
            {isFetchingNewRoutes && (
                <div>is fetching new routes...</div>
            )}
            {!isFetchingNewRoutes && routesList.length > 0 && (
                <Fragment>
                    {routesList.map(route => <ListItem routeObject={route} key={`route-${route.id}`} />)}
                </Fragment>
            )}
            {!isFetchingNewRoutes && routesList.length === 0 && (
                <>
                    <div>Oops! There aren't any routes in this area.</div>
                    <br/>
                    <div>Try removing some filters or panning to a different location.</div>
                </>
            )}
        </div>
    </>
)

const SearchView = () => {
    const { routesList, isFetchingNewRoutes, filterSet, setFilterSet } = useContext(RoutesContext)
    const [showList, setShowList] = useState(true)
    const [showFilters, setShowFilters] = useState(false)
    const [mobilePane, setMobilePane] = useState('map')
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches

    const buttonIcon = showList ? <i className="fa-solid fa-chevron-left"></i> : <i className="fa-solid fa-chevron-right"></i>
    const filtersCtaCopy = showFilters ? 'Hide filters' : 'Show filters'

    const updateFilter = (filterKey, newVal) => {
        const currentFilters = { ...filterSet }

        if (newVal && newVal !== 'any') {
            currentFilters[filterKey] = newVal
        } else {
            delete currentFilters[filterKey]
        }

        setFilterSet(currentFilters)
    }

    return (
        <Container className="search-view-container">
            <div className="filters-container">
                {isMobile && (
                    <div className="mobile-button-container">
                        <div className="mobile-pane-buttons">
                            <Button className="" onClick={() => setMobilePane('list')}><i className="fa-solid fa-list"></i></Button>
                            <Button className="" onClick={() => setMobilePane('map')}><i className="fa-solid fa-map-location-dot"></i></Button>
                        </div>
                        <Button className="" onClick={() => setShowFilters(!showFilters)}>{filtersCtaCopy}&nbsp;&nbsp;<i className="fa-solid fa-sliders"></i></Button>
                    </div>
                )}
                {(!isMobile || (isMobile && showFilters)) && (
                    <>
                        <Form.Field>
                            <Form.Control>
                                <Form.Select
                                    onChange={event => updateFilter('routeType', event.target.value)}
                                    value={filterSet.routeType ?? 'any'}
                                >
                                    <option value="any">
                                        Any type
                                    </option>
                                    <option value="coastal">
                                        Coastal
                                    </option>
                                    <option value="woods">
                                        Woods/rural
                                    </option>
                                    <option value="urban">
                                        Urban
                                    </option>
                                    <option value="mountains">
                                        Mountains
                                    </option>
                                    <option value="fast">
                                        Fast
                                    </option>
                                    <option value="curvy">
                                        Curvy
                                    </option>
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Control>
                                <Form.Select
                                    onChange={event => updateFilter('speed', event.target.value)}
                                    value={filterSet.speed ?? 'any'}
                                >
                                    <option value="any">
                                        Any speed
                                    </option>
                                    <option value="cruise">
                                        Leisurely cruise
                                    </option>
                                    <option value="moderate">
                                        Moderate
                                    </option>
                                    <option value="fast">
                                        Fast
                                    </option>
                                </Form.Select>
                            </Form.Control>
                        </Form.Field>
                        <Form.Field>
                            <Form.Control>
                                <Form.Input
                                    placeholder="Any duration"
                                    type="number"
                                    onChange={event => updateFilter('duration', event.target.value)}
                                    value={filterSet.duration ?? ''}
                                />
                            </Form.Control>
                        </Form.Field>
                    </>
                )}
            </div>
            <Columns className="route-items-container">
                {((!isMobile && showList) || (isMobile && mobilePane === 'list')) && (
                    <Columns.Column className="route-items-column" size={5}>
                        <ListComponent isFetchingNewRoutes={isFetchingNewRoutes} routesList={routesList} />
                    </Columns.Column>
                )}
                {((!isMobile) || (isMobile && mobilePane === 'map')) && (
                    <Columns.Column className="map-column">
                        {!isMobile && <Button className="expand-map-button" onClick={() => setShowList(!showList)}>{buttonIcon}</Button>}
                        <MapComponent />
                    </Columns.Column>
                )}
            </Columns>
        </Container>
    )
}

const WrappedSearchView = () => {
    return (
        <RoutesContextProvider>
            <SearchView />
        </RoutesContextProvider>
    )
}

export default WrappedSearchView;
