import React from "react"
import { Button, Container } from 'react-bulma-components'

import { getCDNOptimizedImage } from '../../utils';

import './styles.css'

const AboutUsView = () => {
    
    return (
        <Container className="about-us-page-container">
            <h1>What is RallyNE?</h1>
            <div className="about-us-container-inner">
                <img alt="our cars and bikes" src={getCDNOptimizedImage({ imagePath: '/about-us-image.png', width: '1000px', quality: 80 })} />
                <p>We all love driving our cars or riding our bikes, but everything inbetween those outings can sometimes detract from that enjoyment; RallyNE was founded to serve as a platform where anyone can come and find great local driving roads quickly and easily. Perfect for that Sunday morning drive you've been dreaming of all week, some impromptu Tuesday afternoon stress relief, or organizing a group ride after a Cars &amp; Coffee. RallyNE is here to make sure you're able to spend as much time as possible behind the wheel, ripping down country roads.</p>
                <br/>
                <br/>
                <p>Have any questions, feedback, or want to meet up for a ride?</p>
                <br/>
                <a href="mailto:rallyneverends@gmail.com">
                    <Button colorVariant="dark" color="primary">
                        Send us an email!
                    </Button>
                </a>
            </div>
        </Container>
    )
}

export default AboutUsView;
