import React, { createContext, useState, useEffect } from "react";

import { fetchRoutes } from "./api"

export const RoutesContext = createContext({});

export const RoutesContextProvider = ({ children }) => {
    const [mapBoundaries, setMapBoundaries] = useState({})
    const [filterSet, setFilterSet] = useState({})
    const [routesList, setRoutesList] = useState([])
    const [isFetchingNewRoutes, setIsFetchingNewRoutes] = useState(false)

    console.log('init context')
    useEffect(() => {
        setIsFetchingNewRoutes(true)
        fetchRoutes(mapBoundaries, filterSet).then(routes => {
            console.log('final value:', routes)
            setRoutesList(routes)
            setIsFetchingNewRoutes(false)
        })
    }, [mapBoundaries, filterSet])

    const routesData = {
        updateMapBoundaries: setMapBoundaries,
        filterSet: filterSet,
        setFilterSet: setFilterSet,
        routesList: routesList,
        isFetchingNewRoutes: isFetchingNewRoutes
    }

    return (
        <RoutesContext.Provider value={routesData}>{children}</RoutesContext.Provider>
    )
}
