import React from "react"
import { Link } from 'react-router-dom'
import { Container } from 'react-bulma-components'

import './styles.css'

const FAQView = () => {
    
    return (
        <Container className="faq-page-container">
            <h1>Frequently asked questions</h1>
            <div className="faq-container-inner">
                <h3>What is RallyNE?</h3>
                <p>RallyNE is a platform where anyone can come and find the best driving roads. Perfect for that Sunday morning drive, for some stress relief, or if you want to get some friends together to go on a drive around town.</p>
                
                <h3>How do I use RallyNE?</h3>
                <p>First, head to our routes page and search for a route you'd like to drive. It will show you the documented route, and open up Google Maps for those waypoints. Adjust the start and end points as needed based on your location, and start driving!</p>
                
                <h3>Do you only have routes for Massachusetts?</h3>
                <p>Currently, yes. We are looking to expand to other states in the future, and you can help! Want to suggest a route in a different area? <Link to="/route/new">Submit a route</Link> and become a part of the RallyNE community.</p>
                
                <h3>Do you have a mobile app?</h3>
                <p>Not at this moment, but it's on our radar!</p>
                
                <h3>Is there a way to drive these routes without Google Maps?</h3>
                <p>Unfortunately, for the time being we're dependent on Google Maps to handle displaying and navigating these routes. We understand that this comes with some downsides for those who prefer Apple Maps, or simply want a more natural navigation experience than Google Maps provides, and we're actively working on a better solution!</p>
            </div>
        </Container>
    )
}

export default FAQView;
