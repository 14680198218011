import React from "react"
import { useHistory } from 'react-router-dom'
import { Button, Container, Columns } from 'react-bulma-components'

import { getCDNOptimizedImage } from "../../utils";

import './styles.css'

const backgroundImageOptions = [
    "/header-bg-image.png",
    "/header-bg-image-2.png",
    "/header-bg-image-3.png",
    "/header-bg-image-4.png",
    "/header-bg-image-5.png",
    "/header-bg-image-6.png",
]

const LandingPageView = () => {
    const history = useHistory()

    const backgroundImage = getCDNOptimizedImage({ 
        imagePath: backgroundImageOptions[Math.floor(Math.random() * backgroundImageOptions.length)],
        width: '1400px',
        quality: 90
    })

    const handleClick = path => {
        history.push(path)
    }
    
    return (
        <Container className="landing-page-container">
            <div id="header-bg-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <h1 className="header-title">Find your road</h1>
                <h4 className="header-subtitle" onClick={() => handleClick('/search')}>Explore routes -&gt;</h4>
            </div>
            <Container className="landing-page-container-inner">
                <h3 className="landing-page-subheader">Where the rally never ends</h3>
                <p className="landing-page-body-copy">Smoothy paved roads, crisp air flowing through open windows, light sparkling through trees as the sun comes up; this is the feeling that inspired RallyNE. Discover your next favorite route, start your engine, roll down the windows, and enjoy the drive.</p>
                <Columns className="cards-container">
                    <Columns.Column className="info-card">
                        <div className="card-icon-wrapper">
                            <img
                                className="card-icon"
                                alt="road icon"
                                src={getCDNOptimizedImage({ imagePath: "/road.svg", width: '120px' })}
                            />
                        </div>
                        <h3>Discover new routes</h3>
                        <p>Spend less time planning and more time driving by using RallyNE to find great driving roads in your area.</p>
                        <Button color="" onClick={() => handleClick('/search')}>Search routes</Button>
                    </Columns.Column>
                    <Columns.Column className="info-card">
                        <div className="card-icon-wrapper">
                            <img
                                className="card-icon"
                                alt="notebook icon"
                                src={getCDNOptimizedImage({ imagePath: "/notebook.svg", width: '120px' })}
                            />
                        </div>
                        <h3>Share your favorites</h3>
                        <p>Send route links to your friends and discover new areas as you explore RallyNE's curated selection of roads.</p>
                        <Button color="" onClick={() => handleClick('/route/new')}>Submit a route</Button>
                    </Columns.Column>
                    <Columns.Column className="info-card">
                        <div className="card-icon-wrapper">
                            <img
                                className="card-icon"
                                alt="driver icon"
                                src={getCDNOptimizedImage({ imagePath: "/car.svg", width: '120px' })}
                            />
                        </div>
                        <h3>Meet other drives</h3>
                        <p>Organize group rides around RallyNE's routes ahead of time, or search and share routes on the go - whichever suits your group!</p>
                        <Button color="" onClick={() => handleClick('/search')}>Find a rally</Button>
                    </Columns.Column>
                </Columns>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </Container>
        </Container>
    )
}

export default LandingPageView;
