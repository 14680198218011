export default [
    {
      "id": 1,
      "submittedAt": "1/15/2022 19:02:18",
      "title": "Rt 127 to Rockport",
      "descriptionShort": "Relaxing drive out to the center of Rockport",
      "descriptionLong": "A great little drive on Rt 127 heading to Rockport - way better than letting Google push you onto the highway. Nice views of the water in a few spots, and I believe most of the speed limits are 25-35mph with a few openings for small pulls. It can get busy on summer weekends when everyone else is also trying to visit Rockport, so be aware you may get stuck in some traffic. The route in general is more of a relaxing drive than anything, and you can always reverse the route on the way back for a second shot.\n\nWould also recommend checking out some of the roads just north of this route near Plum Island if you're looking to take a different way home!",
      "routeType": "Coastal",
      "timeMins": 40,
      "speed": "Leisurely Cruise",
      "pinLatLong": "42.5532091,-70.8468352",
      "routeLink": "https://www.google.com/maps/dir/304+Hale+St,+Beverly,+MA+01915/42.5839823,-70.7176943/42.6244756,-70.6269779/42.6573909,-70.6152395/@42.6285462,-70.7355113,11.83z/data=!4m11!4m10!1m5!1m1!1s0x89e31665574cddb3:0xc93b291e12598c04!2m2!1d-70.8475712!2d42.553544!1m0!1m0!1m0!3e0"
    },
    {
      "id": 2,
      "submittedAt": "1/15/2022 19:20:28",
      "title": "Rt2 to I90",
      "descriptionShort": "Twisty tour of Eastern MA",
      "descriptionLong": "Starting at your choice of a Route 2 exit, this route is pretty accessible to anyone coming from North of Boston. Early in the drive you'll pass the historic Gropius House, previously home to the famous modernist designer Walter Gropius - would highly recommend stopping in for a tour as well. You'll then snake along through Sudbury & Wayland, finishing in Weston near Auburndale.\n\nThe drive is relatively quick, with most sections being 35mph with room to push towards 45mph comfortably. Road quality is great, but BEWARE of the Sherman's Bridge landing about halfway through; it's a bumpy wooden bridge that you definitely don't want to go above 20mph over. If the roads immediately surrounding it weren't so good I'd adjust the route to avoid this.\n\nThere are some great food spots by Route 20 near the end of the route, and on the other side of I95 from the finish point in Auburndale if you'd like a more interesting stopping point.",
      "routeType": "Woods/rural",
      "timeMins": 40,
      "speed": "Moderate",
      "pinLatLong": "42.429013,-71.315821",
      "routeLink": "https://www.google.com/maps/dir/42.4397148,-71.2990679/42.4306436,-71.3337301/42.4068594,-71.4036595/42.3825248,-71.358158/42.3673736,-71.3049251/42.3411063,-71.2727727/@42.3618564,-71.3272939,12z/data=!4m2!4m1!3e0"
    },
    {
      "id": 3,
      "submittedAt": "1/15/2022 19:33:39",
      "title": "Short suburban sling",
      "descriptionShort": "Nice twisties through a quiet suburban area",
      "descriptionLong": "A quick jaunt parallel to I95 starting at Trapelo Road in Lincoln, which itself is quite a fun road. Pavement quality isn't fantastic, but the route makes up for it in how consistent the turns are. You'll sweep through some classic suburban zones before hitting a beautiful section at the end surrounded by private, undeveloped land on either side, which is always a thrill.\n\nThe end of the route is right at the start of the constantly-developing town center in Weston on Boston Post Road, which is an easy pitstop for a bite to eat or to regroup before heading to your next destination.",
      "routeType": "Suburban",
      "timeMins": 10,
      "speed": "Moderate",
      "pinLatLong": "42.423229,-71.291250",
      "routeLink": "https://www.google.com/maps/dir/42.4231741,-71.2912537/42.3669996,-71.3044702/@42.3951042,-71.3196834,13z/data=!3m1!4b1!4m2!4m1!3e0"
    },
    {
      "id": 4,
      "submittedAt": "1/16/2022 22:55:54",
      "title": "Skyline Dr thru Shenandoah",
      "descriptionShort": "A spiritual experience of Appalachia",
      "descriptionLong": "A truly incredible, 3 hour long drive through Shenandoah National Park. Skyline Drive takes you across the ridges of the park, with ~75 scenic spots to stop along the way. There are a few entrances/exits along this route, and you can hop off whenever you'd like - each section is about an hour of driving.\n\nThe speed limit is 35mph all the way through (AFAIK) and there are passing opportunities if you get stuck behind traffic. PLENTY of twisties all the way through, no stop signs, and ample space for fun.\n\nBefore jumping out here - remember that this is in a National Park, so there's an entrance fee and will have some park rangers roaming around.",
      "routeType": "Mountains",
      "timeMins": 180,
      "speed": "Fast",
      "pinLatLong": "38.890721,-78.2033561",
      "routeLink": "https://www.google.com/maps/dir/Dickey+Ridge+Trailhead,+Skyline+Drive,+Front+Royal,+VA/''/''/''/''/@38.4039259,-78.7359944,9.42z/data=!4m32!4m31!1m5!1m1!1s0x89b5c5e0b5d9c4f1:0xee10b295aebb171d!2m2!1d-78.19661!2d38.9062115!1m5!1m1!1s0x89b5cc19cab05961:0xff726648b2565199!2m2!1d-78.1820496!2d38.7936061!1m5!1m1!1s0x89b448de5d3a29a9:0xf0a08e3926f8778d!2m2!1d-78.3636543!2d38.6106955!1m5!1m1!1s0x89b4865f7ca96e73:0x44a1af6a2b7ec8d9!2m2!1d-78.7214298!2d38.2346436!1m5!1m1!1s0x89b364a0ba69cfa7:0x1730460b2b627e4a!2m2!1d-78.8560576!2d38.0375601!3e0"
    },
    {
      "id": 5,
      "submittedAt": "",
      "title": "Honey Pot Hill Orchards to Rt 2 Gardner Loop",
      "descriptionShort": "62 mile loop consisting of tight and curvy residential areas and a long stretch of high speed 2 lane highways",
      "descriptionLong": "Beginning at Honey Pot Hill Orchards, this route goes through quiet residential roads leading up to a nice drive along Rt 117 - be on the lookout for the tree tunnel! - After that you will turn onto 190 for a brief high speed stretch leading directly onto Rt 2. No yield needed, you have your own lane! Follow Rt 2 down until exit 92 with minimal speed enforcement, at which point you will return to town speed roads. You will pass a beautiful pond on your left and then get into very hilly and curvy roads. These have blind hills and corners so drive at your own discretion, but these are a lot of fun! This will then loop you back onto Rt 2 east towards Boston, or keep following the route and end at 495 (with the choice to go north or south).",
      "routeType": "Curvy",
      "timeMins": 90,
      "speed": "Fast",
      "pinLatLong": "42.41161143075144, -71.51387693614386",
      "routeLink": "https://www.google.com/maps/dir/Honey+Pot+Hill+Orchards,+16+Boon+Rd,+Stow,+MA+01775/Ron+Burton+Training+Village,+Morgan+Road,+Hubbardston,+MA/42.431225,-71.5956865/@42.5024,-71.8739696,11.25z/data=!4m20!4m19!1m5!1m1!1s0x89e38da2aeb694a7:0xc77dc29e9bf7b43f!2m2!1d-71.5138706!2d42.4116342!1m10!1m1!1s0x89e15829dd4371b3:0x380ea13d48ed4985!2m2!1d-71.9861769!2d42.5123806!3m4!1m2!1d-71.7232673!2d42.4949082!3s0x89e3efaee7e60273:0x4ab8417cb8917041!1m0!3e0"
    },
    {
      "id": 6,
      "submittedAt": "",
      "title": "Village Hall on the Common - Loop",
      "descriptionShort": "17 mile loop from Framingham to the edge of Weston and back",
      "descriptionLong": "Great shorter route for a casual meetup with friends. Better at non-rush hour times. Route start has a parking lot you can socialize in, and also a large lawn for activites. Follow Edgell Road through Nobscot, then ride on Rt20 for a tame drive through Sudbury and Wayland. Right before you cross into Weston, make a stop at Coach Grill or keep riding back through Wayland on a short curvy path through the woods. Fancy some golf? Have yourself a round at Sandy Burr Country Club! (30 seconds off the route!) End the trip on lightly traveled main roads through Framingham back to Village Hall.",
      "routeType": "Suburban",
      "timeMins": 35,
      "speed": "Leisurely Cruise",
      "pinLatLong": "42.30183620738677, -71.43510586256147",
      "routeLink": "https://www.google.com/maps/dir/Village+Hall+on+the+Common,+2+Oak+St,+Framingham,+MA+01701/Coach+Grill/42.321693,-71.3948859/42.3018248,-71.4350913/@42.3093751,-71.4258439,14.92z/data=!4m21!4m20!1m10!1m1!1s0x89e3883a0afa36df:0x9bb7b51f21933b04!2m2!1d-71.4350207!2d42.3018243!3m4!1m2!1d-71.4376362!2d42.3375088!3s0x89e3892c43f208c9:0x73c406d61404118c!1m5!1m1!1s0x89e3845550380947:0x406f45c6894a0e3d!2m2!1d-71.3342351!2d42.36055!1m0!1m0!3e0"
    },
    {
      "id": 7,
      "submittedAt": "",
      "title": "Brothers Marketplace C&C",
      "descriptionShort": "Tour through Sudbury up to Bedford",
      "descriptionLong": "Starts off strong with two awesome roads.",
      "routeType": "Curvy",
      "timeMins": 50,
      "speed": "Moderate",
      "pinLatLong": "42.3650888,-71.3399352",
      "routeLink": "https://www.google.com/maps/dir/Brothers+Marketplace+-+Weston,+Center+Street,+Weston,+MA/42.372361,-71.349544/42.387573,-71.388826/42.4204504,-71.3721133/42.5084195,-71.3128926/Craft+Food+Hall+Project+-+Revolution+Hall,+Maguire+Road,+Lexington,+MA/@42.4359108,-71.4094121,12z/data=!3m2!4b1!5s0x89e38490682a693b:0xd4ec52aa7b9b2374!4m18!4m17!1m5!1m1!1s0x89e3849061bcb5c3:0xfeb743a7522b3e78!2m2!1d-71.3006466!2d42.3665982!1m0!1m0!1m0!1m0!1m5!1m1!1s0x89e39fc6582cb97d:0xe3e78e0a362da077!2m2!1d-71.2606964!2d42.4727249!3e0"
    },
    {
      "id": 8,
      "submittedAt": "",
      "title": "Framingham Centre to Main Street's Market and Cafe",
      "descriptionShort": "Nice cruise up from 90 to Rt2",
      "descriptionLong": "Great road taking you through Eastern MA.",
      "routeType": "Curvy",
      "timeMins": 35,
      "speed": "Leisurely Cruise",
      "pinLatLong": "42.302852, -71.434721",
      "routeLink": "https://www.google.com/maps/dir/Centre+Common,+Framingham,+MA+01701/Main+Streets+Market+%26+Cafe+OUTDOOR+SEATING+%26+ONLINE+https:%2F%2Fwww.toasttab.com%2Fmain-street-s-market-cafe%2Fv3,+Main+Street,+Concord,+MA/@42.4202999,-71.44193,11.5z/data=!4m29!4m28!1m20!1m1!1s0x89e38900bb91cf2f:0x269ac3f241b25c0a!2m2!1d-71.4347601!2d42.302688!3m4!1m2!1d-71.3969629!2d42.4083868!3s0x89e38fe24ef321dd:0x16a881716d7dd57a!3m4!1m2!1d-71.3888631!2d42.4172219!3s0x89e39008a47efba5:0x4787258ebc5bed23!3m4!1m2!1d-71.3781748!2d42.4093502!3s0x89e39aab0abadc5b:0x943379d35b23aff!1m5!1m1!1s0x89e39a388cbcd0d7:0xb1537018b1670d1d!2m2!1d-71.3502511!2d42.460078!3e0"
    },
    {
      "id": 9,
      "submittedAt": "",
      "title": "Framingham Centre to Treehouse",
      "descriptionShort": "Great roads + great beer = great times",
      "descriptionLong": "Take Rt 20 out from Framingham to Treehouse Brewery for a wonderful tour of MA. Minimal turns involved so this is great for a group drive, plus you have a fantastic final destination. Do the reverse of the route on the way home too!",
      "routeType": "Woods/rural",
      "timeMins": 55,
      "speed": "Moderate",
      "pinLatLong": "42.302852, -71.434721",
      "routeLink": "https://www.google.com/maps/dir/Framingham+Center,+Framingham,+MA/Tree+House+Brewing+Company,+Sturbridge+Road,+Charlton,+MA/@42.288579,-71.8305772,10z/data=!4m17!4m16!1m5!1m1!1s0x89e389aeb49ebbad:0x1836e974ba1cd0b8!2m2!1d-71.437006!2d42.2973176!1m5!1m1!1s0x89e6be8701e9a0c5:0x7ffa9a29a8606352!2m2!1d-72.0123365!2d42.1365912!2m2!1b1!2b1!3e0"
    },
    {
      "id": 10,
      "submittedAt": "",
      "title": "Blue Hills cruise",
      "descriptionShort": "Windy wooded roads close to Boston",
      "descriptionLong": "Arguably one the best roads close to downtown Boston. Great curves, great views, and plenty of space on either side of the road too. Only downsides are that it's a popular hiking and biking area so you'll have to be careful of pedestrians, particularly at some of the small lots along the road, and there's a police station towards the end of the route. Never really seen cops driving the road before but definitely a higher likelihood of passing one on the other side of the road than your average random forest road.",
      "routeType": "Woods/rural",
      "timeMins": 10,
      "speed": "Moderate",
      "pinLatLong": "42.237503, -71.031842",
      "routeLink": "https://www.google.com/maps/dir/42.2081692,-71.1193064/42.2374222,-71.0319274/@42.2230226,-71.0928061,13.57z/data=!4m2!4m1!3e0"
    },
    {
      "id": 11,
      "submittedAt": "",
      "title": "Mohawk Trail - Athol to Williamstown",
      "descriptionShort": "Beautiful scenery in NW Mass, roadside views",
      "descriptionLong": "Long ride along the end of Rt 2 before jumping onto Mowhawk Trail, which is an incredible road running alongside a river. Spectactular destination drive that's well worth a visit. Most of the roads are pretty rural as well, plenty of opportunities to safely pick up the pace.",
      "routeType": "Woods/rural",
      "timeMins": 90,
      "speed": "Moderate",
      "pinLatLong": "42.5577143,-72.2863121",
      "routeLink": "https://www.google.com/maps/dir/42.5577143,-72.2863121/42.7136109,-73.209112/@42.5604026,-72.2906429,16.3z/data=!4m9!4m8!1m5!3m4!1m2!1d-72.95073!2d42.6413256!3s0x89e0cf74f382db2f:0x775dfa3b534994d7!1m0!3e0"
    },
    {
      "id": 12,
      "submittedAt": "",
      "title": "Framingham Center to Trombetta's Farm",
      "descriptionShort": "",
      "descriptionLong": "Quick ride from Framingham towards Marlborough, ending at a nice little farm.",
      "routeType": "Woods/rural",
      "timeMins": 15,
      "speed": "Moderate",
      "pinLatLong": "42.302852, -71.434721",
      "routeLink": "https://www.google.com/maps/dir/Centre+Common,+Framingham,+MA+01701/Trombetta's+Farm,+Farm+Road,+Marlborough,+MA/@42.3368627,-71.5228903,13.12z/data=!4m15!4m14!1m5!1m1!1s0x89e38900bb91cf2f:0x269ac3f241b25c0a!2m2!1d-71.4347601!2d42.302688!1m5!1m1!1s0x89e38b8fbe1ca631:0x263d10ced4512f58!2m2!1d-71.5128017!2d42.3429259!3e0!5i1"
    },
    {
      "id": 13,
      "submittedAt": "",
      "title": "P-Town loop",
      "descriptionShort": "Secluded beach loop at the end of Cape Cod",
      "descriptionLong": "What a fantastic destination drive. A quick little loop to hit while visiting Provincetown, and a great way to end an exploration of the best roads of the Cape. There are usually some people walking/biking around on this road but the views are stunning and the asphalt is perfect. Plus, you get to go to a beach! The long stretch on Rt 6 is a great place for pulls as well...",
      "routeType": "Coastal",
      "timeMins": 25,
      "speed": "Moderate",
      "pinLatLong": "42.0490683,-70.1054847",
      "routeLink": "https://www.google.com/maps/dir/42.0490683,-70.1054847/42.0592149,-70.1892157/42.0460408,-70.215615/42.0716639,-70.2096939/42.0490683,-70.1054847/@42.0483835,-70.1054274,18.12z/data=!4m2!4m1!3e0"
    },
    {
      "id": 14,
      "submittedAt": "",
      "title": "Sherborn to Lookout Farm",
      "descriptionShort": "Wooded farmland roads ending at a farm",
      "descriptionLong": "Nice short drive through some more rural areas landing you at a small farm.",
      "routeType": "Woods/rural",
      "timeMins": 15,
      "speed": "Leisurely Cruise",
      "pinLatLong": "42.2398615,-71.3686335",
      "routeLink": "https://www.google.com/maps/dir/Dunkin'/Lookout+Farm,+Pleasant+Street+South,+Natick,+MA/@42.2388236,-71.3540749,13z/data=!4m19!4m18!1m10!1m1!1s0x89e47810edef7b8d:0x4899e81b1bed8a70!2m2!1d-71.3690359!2d42.2399856!3m4!1m2!1d-71.312745!2d42.2390908!3s0x89e4789f311bd175:0xae96634a633a64ba!1m5!1m1!1s0x89e3873fbac49693:0x2b4d950f5f28ae94!2m2!1d-71.3090557!2d42.2631526!3e0"
    }
  ]