import React, { useState } from 'react'
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer, DirectionsStatus } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '400px',
  borderRadius: '6px'
};

const RouteService = new DirectionsService()

const DetailMapComponent = ({ routeObject }) => {
    const [directions, setDirections] = useState()

    const center = {
        lat: Number.parseFloat(routeObject.pinLatLong.split(',')[0]),
        lng: Number.parseFloat(routeObject.pinLatLong.split(',')[1])
    }

    const waypoints = routeObject.routeLink.split('/dir/')[1].split('/@')[0].split('/').map(pointString => ({ location: pointString }))
    const origin = waypoints.shift().location
    const destination = waypoints.pop().location

    return (
        <LoadScript googleMapsApiKey="AIzaSyDt7v_olyWmKuaVDxfWXRvxICbSkWPffGQ">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={11}
            >
                {!directions && (
                    <DirectionsService 
                        options={{
                            origin: origin,
                            destination: destination,
                            travelMode: 'DRIVING',
                            waypoints: waypoints
                        }}
                        callback={(response) => {
                            if (response.status === 'OK') {
                                setDirections(response)
                            } else {
                                console.error(response)
                            }
                        }}
                    />
                )}
                {directions && <DirectionsRenderer options={{ directions: directions }} />}
            </GoogleMap>
        </LoadScript>
    )
}

export default DetailMapComponent
