export const getCDNOptimizedImage = ({
    imagePath,
    ratio,
    width,
    height,
    quality,
    blur,
  }) => {
    const parameters = ['dpr-auto', 'pr-true', 'di-logo_icon_CwvvbRzw9.svg', 'c-maintain_ratio'];
  
    if (ratio) {
      parameters.push(`ar-${ratio.replace(':', '-')}`);
    }
    if (width) {
      parameters.push(`w-${parseInt(width)}`);
    }
    if (height) {
      parameters.push(`h-${parseInt(height)}`);
    }
    if (quality && quality > 0 && quality <= 100) {
      parameters.push(`q-${quality}`);
    }
  
    if (blur && blur > 0 && blur <= 100) {
      parameters.push(`bl-${blur}`);
    }
  
    const queryParams = parameters.length > 0 ? `?tr=${parameters.filter(Boolean).join(',')}` : '';
  
    return `https://ik.imagekit.io/rallyne${imagePath}${queryParams}`;
  };